import { cn } from '@utils/cn';

export const AnimatedEllipsis = ({ className }: { className?: string }) => {
  return (
    <div className={cn('ml-1 flex gap-1 text-xl', className)}>
      <span className={'animatedElipsisEl'}>.</span>
      <span className={'animatedElipsisEl'}>.</span>
      <span className={'animatedElipsisEl'}>.</span>
    </div>
  );
};
