import { useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import { useStore } from '@store/store';

/**
 * Initial data the app depends on
 */
export const useLoadedInitialBeData = () => {
  const availableTokens = useStore((state) => state.availableTokens, shallow);

  // networks and tokens data are all fetched simultaneously so we can just check tokens here
  const loadedData = useMemo(() => !!availableTokens, [availableTokens]);

  return loadedData;
};
