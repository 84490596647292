import { Button } from '@mui/material';
import { motion } from 'framer-motion';
import isMobile from 'is-mobile';
import { useMemo, useRef } from 'react';
import { IS_CARDANO_ENABLED } from 'src/contants/app';
import { CARDANO_NETWORK } from 'src/contants/cardano';
import { CONNECT_BUTTON_DEFAULT_STYLE } from 'src/contants/connect-button';
import { MELD_NETWORK } from 'src/contants/meld';
import { shallow } from 'zustand/shallow';

import { useStore } from '@store/store';

import { AnimatedEllipsis } from '@components/animated-ellipsis';
import { TokenIcon } from '@components/token-icon';

import { cn } from '../../utils/cn';
import { shortenAddress } from '../../utils/shorten-address';

type Props = {
  onClickCardanoAddress: (e: React.MouseEvent<HTMLElement>) => void;
  onClickEvmAddress: (e: React.MouseEvent<HTMLElement>) => void;
  onClickConnect: (e: React.MouseEvent<HTMLElement>) => void;
  connectingCardano: boolean;
  connectingEvm: boolean;
  className?: string;
  cardanoAddress?: string | null;
  evmAddress?: string;
  menuOpen: boolean;
  cardanoIcon?: string;
  evmIcon?: string;
};

const _isMobile = isMobile();

export const ConnectButton = ({
  cardanoAddress,
  evmAddress,
  onClickCardanoAddress,
  onClickEvmAddress,
  onClickConnect,
  connectingCardano,
  connectingEvm,
  cardanoIcon,
  evmIcon,
}: Props) => {
  const networks = useStore((state) => state.networks, shallow);

  const cardanoEl = useRef<HTMLButtonElement>(null);

  const onClickFirstButton = useMemo(
    () =>
      _isMobile
        ? evmAddress
          ? onClickEvmAddress
          : onClickConnect
        : cardanoAddress
          ? onClickCardanoAddress
          : onClickConnect,
    [cardanoAddress, evmAddress, onClickCardanoAddress, onClickConnect, onClickEvmAddress],
  );

  const classNameFirstButton = useMemo(
    () =>
      cn(
        CONNECT_BUTTON_DEFAULT_STYLE,
        (cardanoAddress || evmAddress) && 'rounded-r-none border-0 lowercase',
        (connectingCardano ||
          (!cardanoAddress && connectingEvm) ||
          (!cardanoAddress && evmAddress) ||
          (!evmAddress && !cardanoAddress)) &&
          'uppercase',
        !IS_CARDANO_ENABLED && 'hidden',
      ),
    [cardanoAddress, connectingCardano, connectingEvm, evmAddress],
  );

  const tokenIconFirstButton = useMemo(
    () =>
      !_isMobile && cardanoIcon
        ? cardanoIcon
        : !_isMobile && (cardanoAddress || connectingCardano || evmAddress)
          ? (networks?.[CARDANO_NETWORK]?.darkIcon ?? '')
          : (networks?.[MELD_NETWORK]?.darkIcon ?? ''),
    [cardanoAddress, cardanoIcon, connectingCardano, evmAddress, networks],
  );

  const textFirstButton = useMemo(
    () =>
      connectingCardano || (!cardanoAddress && connectingEvm) ? (
        <>CONNECTING {_isMobile ? '' : <AnimatedEllipsis />}</>
      ) : (!cardanoAddress && !_isMobile) || (_isMobile && !evmAddress) ? (
        'CONNECT'
      ) : (
        shortenAddress(_isMobile ? (evmAddress as string) : (cardanoAddress ?? ''), 4, 5)
      ),
    [cardanoAddress, connectingCardano, connectingEvm, evmAddress],
  );

  const onClickSecondButton = useMemo(
    () => (evmAddress ? onClickEvmAddress : onClickConnect),
    [evmAddress, onClickEvmAddress, onClickConnect],
  );

  const classNameSecondButton = useMemo(
    () =>
      cn(
        CONNECT_BUTTON_DEFAULT_STYLE,
        IS_CARDANO_ENABLED && 'border-0 border-transparent rounded-l-none',
        (connectingEvm || !evmAddress) && 'uppercase',
      ),
    [connectingEvm, evmAddress],
  );

  const tokenIconSecondButton = useMemo(
    () => (evmIcon ? evmIcon : (networks?.[MELD_NETWORK]?.darkIcon ?? '')),
    [evmIcon, networks],
  );

  const textSecondButton = useMemo(
    () =>
      connectingEvm ? (
        <>
          CONNECTING <AnimatedEllipsis />
        </>
      ) : evmAddress ? (
        shortenAddress(evmAddress, 4, 5)
      ) : (
        'CONNECT'
      ),
    [connectingEvm, evmAddress],
  );

  return (
    <div className="flex h-max items-center">
      <div
        className={cn(
          'relative flex h-max items-center overflow-hidden rounded-lg',
          (cardanoAddress || evmAddress) && 'shadow-[inset_0px_0px_0px_1px_#D9D9D9]',
        )}
      >
        <motion.div
          initial={{ width: 0 }}
          animate={
            IS_CARDANO_ENABLED
              ? {
                  width: _isMobile
                    ? connectingEvm
                      ? 165
                      : 145
                    : connectingCardano || (!cardanoAddress && connectingEvm)
                      ? 220
                      : 183,
                }
              : {}
          }
          whileHover={{ scale: 1.02 }}
        >
          <Button onClick={onClickFirstButton} variant="outlined" className={classNameFirstButton}>
            {(connectingCardano || connectingEvm || cardanoAddress || evmAddress) && (
              <TokenIcon
                boxShadow={false}
                innerWrapperClassName="h-[24px] w-[24px] -translate-x-1"
                imgSrc={tokenIconFirstButton}
              />
            )}
            {textFirstButton}
          </Button>
        </motion.div>
        <motion.div
          initial={{ width: 0, opacity: 0 }}
          animate={IS_CARDANO_ENABLED && (cardanoAddress || evmAddress) ? { width: 1, opacity: 0.4 } : {}}
          className={'h-[48px] bg-white md:h-[58px]'}
        />
        <motion.div
          initial={{ width: 0, opacity: 0 }}
          animate={
            (!_isMobile || !IS_CARDANO_ENABLED) && (evmAddress || cardanoAddress || !IS_CARDANO_ENABLED)
              ? {
                  width: !IS_CARDANO_ENABLED && _isMobile ? (connectingEvm ? 165 : 145) : connectingEvm ? 220 : 183,
                  opacity: 1,
                }
              : {}
          }
          whileHover={{ scale: 1.02 }}
        >
          <Button ref={cardanoEl} onClick={onClickSecondButton} variant="outlined" className={classNameSecondButton}>
            {IS_CARDANO_ENABLED && (
              <TokenIcon
                imgSrc={tokenIconSecondButton}
                boxShadow={false}
                innerWrapperClassName="h-[24px] w-[24px] -translate-x-[2px]"
              />
            )}
            {textSecondButton}
          </Button>
        </motion.div>
      </div>
    </div>
  );
};
