import { WalletToken } from '@typings/wallet-asset.types';
import { motion } from 'framer-motion';
import { ReactNode, memo, useCallback } from 'react';
import { MELD_NETWORK } from 'src/contants/meld';
import { POPUP_HEIGHT, POPUP_WIDTH } from 'src/contants/popup';

import { useStore } from '@store/store';

import { BorrowedAsset, LiquidationsType, SuppliedAsset } from '@api/liquidations/types';

import { cn } from '@utils/cn';
import { formatCurrency } from '@utils/format-currency';
import { networks } from '@utils/networks/networks';
import { shortenAddress } from '@utils/shorten-address';

import copyIcon from '../assets/copy.svg';
import shareIcon from '../assets/share.svg';
import { TokenIcon } from './token-icon';

type StackProps = {
  title: string;
  amount: string;
  assets: Array<ReactNode>;
};

export const AddressAndTools = ({ address, className }: { address: string; className?: string }) => {
  const copyToClipboard = useCallback(() => navigator.clipboard.writeText(address as string), [address]);

  return (
    <div className={cn('flex gap-2 items-center', className)}>
      <p className="text-[12px] font-bold">{shortenAddress(address, 4, 4)}</p>
      <div className="flex gap-1">
        <img width={14} onClick={copyToClipboard} src={copyIcon} className="cursor-pointer hover:opacity-60" />
        <img
          width={14}
          onClick={() =>
            window.open(`${networks[MELD_NETWORK]?.blockExplorers.default.url}/address/${address}`, '_blank')
          }
          src={shareIcon}
          className="cursor-pointer hover:opacity-60"
        />
      </div>
    </div>
  );
};

const Stack = ({ title, amount, assets }: StackProps) => {
  return (
    <div className="flex flex-col items-center gap-1">
      <p className="m-0 -mb-1 font-semibold text-center text-meldwhite/60">{amount}</p>
      <p className="m-0 font-semibold text-meldwhite">{title}</p>
      <div className="flex gap-[6px] [&_div]:w-[18px] [&_div]:h-[18px] justify-center">
        {assets.slice(0, 4).map((asset) => asset)}
      </div>
    </div>
  );
};

type CardProps = {
  address: string;
  totalBorrowed: number;
  totalSupplied: number;
  borrowedAssets: Array<BorrowedAsset>;
  suppliedAssets: Array<SuppliedAsset>;
  userTokensByContract: Record<string, WalletToken>;
  selected: boolean;
};

const Card = memo(
  ({
    address,
    totalBorrowed,
    totalSupplied,
    borrowedAssets,
    suppliedAssets,
    userTokensByContract,
    selected,
  }: CardProps) => {
    const setAppData = useStore((state) => state.setAppData);

    return (
      <motion.div
        onClick={(e) => {
          const { clientX, clientY } = e;
          const { innerWidth, innerHeight } = window;
          const halfScreenWidth = innerWidth / 2;
          const halfScreenHeight = innerHeight / 2;

          const popupPoint0 = [halfScreenWidth - POPUP_WIDTH / 2, halfScreenHeight - POPUP_HEIGHT / 2];

          const offsetWidth = popupPoint0[0] - clientX;
          const offsetHeight = popupPoint0[1] - clientY;
          setAppData({
            selectedUser: address,
            selectedFromPointX: clientX < popupPoint0[0] ? offsetWidth * -1 : offsetWidth * -1,
            selectedFromPointY: clientY < popupPoint0[1] ? offsetHeight : offsetHeight * -1,
          });
        }}
        whileHover={{ scale: 1.02, background: '#ffffff12' }}
        animate={selected ? { scale: 1.02, background: '#ffffff12' } : {}}
        className={cn(
          'text-meldwhite w-full  overflow-hidden md:w-[220px] shadow-md border border-meldwhite cursor-pointer border-solid rounded-lg px-3 py-4',
        )}
      >
        <AddressAndTools address={address} className="mb-5" />
        <div className="flex items-center justify-between">
          <Stack
            title="Borrowed"
            amount={formatCurrency(totalBorrowed)}
            assets={borrowedAssets.map((a) => (
              <div key={a.contract} className={cn(!userTokensByContract[a.contract] && 'opacity-60')}>
                <TokenIcon imgSrc={a.tokenIcon} boxShadow={false} />
              </div>
            ))}
          />
          <Stack
            title="Collateral"
            amount={formatCurrency(totalSupplied)}
            assets={suppliedAssets.map((a) => (
              <TokenIcon imgSrc={a.tokenIcon} boxShadow={false} />
            ))}
          />
        </div>
      </motion.div>
    );
  },
);

type Props = {
  data: LiquidationsType;
  userTokensByContract: Record<string, WalletToken>;
  selectedUser: string | null;
};

export const Liquidations = memo(({ data, userTokensByContract, selectedUser }: Props) => {
  return (
    <div className="max-w-[100%] mx-auto grid gap-4 mt-20 w-full md:w-auto grid-cols-1 md:grid-cols-[repeat(auto-fit,minmax(220px,1fr))]">
      {data.map((data) => (
        <div className="relative">
          <Card
            totalBorrowed={data.totalBorrowedFiat}
            totalSupplied={data.totalSuppliedFiat}
            address={data.address}
            borrowedAssets={data.borrowedAssets}
            suppliedAssets={data.suppliedAssets}
            userTokensByContract={userTokensByContract}
            selected={selectedUser === data.address}
          />
        </div>
      ))}
      {!data.length && (
        <p className="font-bold uppercase text-xl text-meldwhite/60 mt-20 text-center">no users to liquidate</p>
      )}
    </div>
  );
});
