import { axiosInstance } from '@api/axios';

import { LiquidationsResponseType } from './types';

export const getLiquidationsQuery = async (): Promise<LiquidationsResponseType> => {
  return ((await axiosInstance.get('/lending/user/liquidatable')).data as LiquidationsResponseType).filter(
    (userPosition) => {
      if (!userPosition.suppliedAssets.find((suppliedAsset) => suppliedAsset.isCollateral)) return false;
      return true;
    },
  );
};

export const GET_LIQUIDATIONS = 'GET_LIQUIDATIONS';
