import { axiosInstance } from '../../axios';
import { AvailableTokensResponse } from './available-tokens.types';

type params = {
  tokenName?: string;
};

export const getAvailableTokensQuery = async (params?: params): Promise<AvailableTokensResponse> => {
  return (
    await axiosInstance.get('/tokens', {
      params: { tokenName: params?.tokenName },
    })
  ).data;
};

export const GET_AVAILABLE_TOKENS_QUERY_KEY = 'GET_AVAILABLE_TOKENS_QUERY_KEY';
