import Flicking from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';
import { Button, Slider } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MELD_NETWORK, MELD_TOKEN_ADDRESS } from 'src/contants/meld';
import { POPUP_HEIGHT, POPUP_WIDTH } from 'src/contants/popup';
import { encodeFunctionData, formatUnits, parseUnits } from 'viem';
import {
  useEstimateFeesPerGas,
  useEstimateGas,
  useSimulateContract,
  useWaitForTransactionReceipt,
  useWriteContract,
} from 'wagmi';
import { shallow } from 'zustand/shallow';

import { useStore } from '@store/store';

import { GET_LIQUIDATIONS } from '@api/liquidations/query';
import { queryClient } from '@api/query-client';

import { useUnsupportedExtNetwork } from '@hooks/use-unsupported-ext-network';

import { cn } from '@utils/cn';
import { formatCryptoBalance, formatCurrency } from '@utils/format-currency';
import { convertEVMHashToLink } from '@utils/transaction-link.util';

import ERC20Abi from '../abi/ERC20.json';
import MELDLendingPoolAbi from '../abi/MELDLendingPool.json';
import { AnimatedEllipsis } from './animated-ellipsis';
import { ErrorMessage } from './error-message';
import { AddressAndTools } from './liquidations';
import { TokenIcon } from './token-icon';
import { Input } from './token-selector/input';

const CloseIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 1.5L1.5 10.5M1.5 1.5L10.5 10.5"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Title = ({ text }: { text: string }) => <p className="text-meldwhite mb-4 font-bold mt-7 text-[20px]">{text}</p>;

const SmallCard = forwardRef(
  (
    {
      tokenIcon,
      balance,
      tokenSymbol,
      selected,
      onClick,
      usdBalance,
    }: {
      tokenIcon: string;
      balance: number;
      tokenSymbol: string;
      selected: boolean;
      onClick: () => void;
      usdBalance?: number;
    },
    ref,
  ) => {
    return (
      <motion.div
        // @ts-expect-error 2322
        ref={ref}
        onClick={onClick}
        initial={{ opacity: 0.9 }}
        whileHover={{ scale: selected ? 1.1 : 1, opacity: 1 }}
        animate={
          selected
            ? { scale: 1.1, background: '', opacity: 1, height: usdBalance !== undefined ? 80 : 60 }
            : { scale: 0.95 }
        }
        className={cn(
          'flex panel select-none shrink-0 items-center cursor-pointer flex-col justify-center gap-[6px] w-[90px] h-[60px] border border-solid border-meldwhite rounded-md',
          selected && 'border-meldgreen',
        )}
      >
        <TokenIcon
          imgSrc={tokenIcon}
          boxShadow={false}
          innerWrapperClassName="w-[23px] h-[23px]"
          mainWrapperClassName=" pointer-events-none"
        />
        <div className="flex flex-col gap-1 items-center justify-center">
          <p className="text-[11px] font-semibold">
            {!balance ? '-' : formatCryptoBalance(balance, tokenSymbol, true)}
          </p>
          {usdBalance && <p className="text-[10px] font-semibold">{formatCurrency(usdBalance)}</p>}
        </div>
      </motion.div>
    );
  },
);

const Slide = ({
  className,
  max,
  onChange,
  value,
}: {
  className?: string;
  max: number;
  value: number;
  onChange: (val: number) => void;
}) => {
  return (
    <Slider
      value={value}
      step={0.1}
      onChange={(_e, val) => onChange(val as number)}
      max={max}
      slotProps={{
        thumb: {
          className:
            'bg-meldwhite ring-2 w-5 h-5 flex items-center justify-center bg-white rounded-full shadow absolute',
        },
        root: { className: cn('w-full relative inline-block h-2 cursor-pointer', className) },
        rail: {
          className: 'bg-meldwhite opacity-100',
        },
        track: {
          className: 'bg-meldgreen h-2 rounded-full border-none',
        },
      }}
    />
  );
};

const DISABLED_CLASS = 'pointer-events-none opacity-60';

const Carousel = ({ loading, data }: { loading: boolean; data: ReactNode }) => {
  return (
    <div className={cn('relative w-full transition-opacity', loading && DISABLED_CLASS)}>
      <div className="absolute w-[45px] z-10 -right-4 h-[120%] bg-[linear-gradient(to_left,#e51c44_35%,transparent_100%)]" />
      <div className="absolute w-[45px] z-10 -left-4 h-[120%] bg-[linear-gradient(to_right,#e51c44_35%,transparent_100%)]" />
      <Flicking
        moveType="snap"
        align={'prev'}
        cameraClass="h-[120%] flex gap-3 items-center justify-center"
        renderOnSameKey
        circular
      >
        <div className="opacity-0 panel pointer-events-none">
          <SmallCard balance={0} tokenIcon={''} tokenSymbol={''} selected={false} onClick={() => {}} />
        </div>
        {data}
        <div className="opacity-0 panel pointer-events-none">
          <SmallCard balance={0} tokenIcon={''} tokenSymbol={''} selected={false} onClick={() => {}} />
        </div>
      </Flicking>
    </div>
  );
};

export const Popup = () => {
  const selectedUser = useStore((state) => state.appData.selectedUser);
  const setAppData = useStore((state) => state.setAppData);
  const selectedFromPointX = useStore((state) => state.appData.selectedFromPointX);
  const selectedFromPointY = useStore((state) => state.appData.selectedFromPointY);
  const { wrongEvmNetwork } = useUnsupportedExtNetwork();
  const [error, setError] = useState(false);
  const numberFormatting = useStore((state) => state.numberFormatting);
  const lendingPoolContractAddress = useStore((state) => state.lendingPoolContractAddress);
  const [hash, setHash] = useState<`0x${string}`>();
  const liquidationHashRef = useRef<`0x${string}` | null>(null);
  const userTokens = useStore((state) => state.userTokens, shallow);
  const [inputKey, setInputKey] = useState(0);

  const liquidations = useStore((state) => state.appData.liquidations, shallow);
  const [selectedDebt, setSelectedDebt] = useState('');
  const [selectedCollateral, setSelectedCollateral] = useState('');
  const [debtAmount, setDebtAmount] = useState('');
  const [percentage, setPercentage] = useState(0);

  const [mustApprove, setMustApprove] = useState(false);

  const [loading, setLoading] = useState(false);
  const [liquidationSuccess, setLiquidationSuccess] = useState<null | boolean>(null);

  const onClose = () => {
    if (loading) return;
    setAppData({ selectedUser: null });
  };

  const data = useMemo(() => liquidations.find((a) => a.address === selectedUser), [liquidations, selectedUser]);

  useEffect(() => {
    setPercentage(0);
    setDebtAmount('');
  }, [selectedDebt]);

  useEffect(() => {
    setPercentage(0);
    setSelectedDebt(data?.borrowedAssets.length === 1 ? data.borrowedAssets[0].tokenSymbol : '');
    setSelectedCollateral(data?.suppliedAssets.length === 1 ? data.suppliedAssets[0].tokenSymbol : '');
    setDebtAmount('');
  }, [selectedUser, data]);

  const userToken = useMemo(
    () => (selectedDebt ? userTokens.find((a) => a.symbol === selectedDebt) : null),
    [userTokens, selectedDebt],
  );

  const userDebtToken = useMemo(
    () => (selectedDebt ? data?.borrowedAssets.find((a) => a.tokenSymbol === selectedDebt) : null),
    [selectedDebt, data],
  );

  const userCollateralToken = useMemo(
    () => (selectedCollateral ? data?.suppliedAssets.find((a) => a.tokenSymbol === selectedCollateral) : null),
    [selectedCollateral, data],
  );

  const maxAmount = Math.min(
    +(userToken?.amount ?? 0),
    !userDebtToken ? 0 : +userDebtToken.stable.fiatAmount || +userDebtToken.variable.fiatAmount,
    //!userCollateralToken
    //? 0
    //: +formatUnits(BigInt(userCollateralToken.totalSuppliedAmount), userCollateralToken.tokenDecimals),
  );

  const handleErrorFlag = useCallback((flag: boolean) => setError(flag), []);

  const internalPopupHeight = useMemo(() => (error ? POPUP_HEIGHT + 70 : POPUP_HEIGHT), [error]);

  const debtDollarAmount = useMemo(() => {
    const debtAmountDollar = +debtAmount * +(userToken?.price ?? 0);
    const maxDebtAmountDollar = maxAmount * +(userToken?.price ?? 0);
    return Math.min(debtAmountDollar, maxDebtAmountDollar);
  }, [debtAmount, userToken, maxAmount]);

  const { data: estimateFeePerGas } = useEstimateFeesPerGas();

  const {
    data: simulationData,
    error: simulationError,
    isLoading: simulationLoading,
    fetchStatus: simulationFetchStatus,
    queryKey: simulationQueryKey,
  } = useSimulateContract({
    abi: MELDLendingPoolAbi,
    address: lendingPoolContractAddress,
    functionName: 'liquidationCall',
    args: [
      userCollateralToken?.contract,
      userDebtToken?.contract,
      selectedUser,
      parseUnits(debtAmount, userToken?.decimals ?? 18),
      false,
    ],
    query: {
      enabled: !!userCollateralToken && !!userDebtToken && +debtAmount > 0,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retryOnMount: false,
      refetchOnReconnect: false,
      staleTime: 0,
      cacheTime: 0,
    },
  });

  const {
    data: liquidationEstimationData,
    isLoading: liquidationEstimationLoading,
    fetchStatus: liquidationEstimationFetchStatus,
    queryKey: liquidationEstimationQueryKey,
  } = useEstimateGas({
    to: lendingPoolContractAddress ?? MELD_TOKEN_ADDRESS,
    data: encodeFunctionData({
      abi: MELDLendingPoolAbi,
      functionName: 'liquidationCall',
      args: [
        userCollateralToken?.contract ?? MELD_TOKEN_ADDRESS,
        userDebtToken?.contract ?? MELD_TOKEN_ADDRESS,
        selectedUser ?? MELD_TOKEN_ADDRESS,
        parseUnits(debtAmount, userToken?.decimals ?? 18),
        false,
      ],
    }),
    query: {
      enabled: !mustApprove && +debtAmount > 0 && !!selectedDebt,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 0,
      cacheTime: 0,
    },
  });

  const {
    writeContract: writeContractApprove,
    data: approveHash,
    isLoading: approveIsLoading,
    isError: approveIsError,
    reset: resetWriteContractApprove,
  } = useWriteContract();

  const {
    writeContract: writeContractLiquidation,
    data: liquidationHash,
    isLoading: liquidationIsLoading,
    isError: liquidationIsError,
    reset: resetWriteContractLiquidation,
  } = useWriteContract();

  // reset loading state if an error occurs (ex: user rejects tx)
  useEffect(() => {
    if (approveIsError || liquidationIsError) setLoading(false);
  }, [approveIsError, liquidationIsError]);

  const {
    data: liquidationReceipt,
    isSuccess: isConfirmedTransaction,
    isError: isErrorTransaction,
  } = useWaitForTransactionReceipt({
    confirmations: 1,
    hash,
    query: {
      enabled: !!hash,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  });

  useEffect(() => {
    if (approveHash) setHash(approveHash);
  }, [approveHash]);

  useEffect(() => {
    if (liquidationHash) setHash(liquidationHash);
  }, [liquidationHash]);

  useEffect(() => {
    if (isConfirmedTransaction || isErrorTransaction) {
      if (mustApprove) {
        setHash(undefined);
        setLoading(false);
        setMustApprove(false);
        resetWriteContractApprove();
        queryClient.invalidateQueries({ queryKey: simulationQueryKey });
      } else {
        liquidationHashRef.current = hash as `0x${string}`;
        setHash(undefined);
        resetWriteContractLiquidation();
        setTimeout(() => {
          queryClient.invalidateQueries({ queryKey: liquidationEstimationQueryKey });
          setDebtAmount('');
          setInputKey((oldState) => oldState + 1);
          setPercentage(0);
          queryClient.invalidateQueries([GET_LIQUIDATIONS]);
          setLoading(false);
          setLiquidationSuccess(liquidationReceipt?.status === 'success' ? true : false);
        }, 5000);
      }
    }
  }, [
    isConfirmedTransaction,
    isErrorTransaction,
    mustApprove,
    resetWriteContractApprove,
    resetWriteContractLiquidation,
    liquidationEstimationQueryKey,
    simulationQueryKey,
    liquidationReceipt,
    hash,
  ]);

  useEffect(() => {
    if (simulationError?.message.includes('insufficient allowance')) setMustApprove(true);
    else if (simulationData) setMustApprove(false);
  }, [simulationError, simulationData]);

  const {
    data: approveEstimationData,
    isLoading: approveEstimationLoading,
    fetchStatus: approveEstimationFetchStatus,
  } = useEstimateGas({
    to: userDebtToken?.contract ?? MELD_TOKEN_ADDRESS,
    data: encodeFunctionData({
      abi: ERC20Abi,
      functionName: 'approve',
      args: [lendingPoolContractAddress ?? MELD_TOKEN_ADDRESS, parseUnits(debtAmount, userToken?.decimals ?? 18)],
    }),
    query: {
      enabled: mustApprove && +debtAmount > 0 && !!selectedDebt,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  });

  const handleApprove = () => {
    writeContractApprove({
      maxFeePerGas: estimateFeePerGas?.maxFeePerGas,
      maxPriorityFeePerGas: estimateFeePerGas?.maxPriorityFeePerGas,
      abi: ERC20Abi,
      address: userDebtToken?.contract,
      functionName: 'approve',
      args: [
        lendingPoolContractAddress ?? MELD_TOKEN_ADDRESS,
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
      ],
    });
  };

  const handleLiquidation = () => {
    writeContractLiquidation({
      maxFeePerGas: estimateFeePerGas?.maxFeePerGas,
      maxPriorityFeePerGas: estimateFeePerGas?.maxPriorityFeePerGas,
      abi: MELDLendingPoolAbi,
      address: lendingPoolContractAddress,
      functionName: 'liquidationCall',
      args: [
        userCollateralToken?.contract,
        userDebtToken?.contract,
        selectedUser,
        parseUnits(debtAmount, userToken?.decimals ?? 18),
        false,
      ],
    });
  };

  const onConfirm = () => {
    if (liquidationSuccess !== null) {
      setLiquidationSuccess(null);
      return;
    }
    setLoading(true);
    if (mustApprove) {
      handleApprove();
    } else {
      handleLiquidation();
    }
  };

  const isLoading =
    (approveEstimationLoading && approveEstimationFetchStatus !== 'idle') ||
    (simulationLoading && simulationFetchStatus !== 'idle') ||
    (liquidationEstimationLoading && liquidationEstimationFetchStatus !== 'idle');
  approveIsLoading || liquidationIsLoading;

  const buttonDisabled =
    liquidationSuccess === null &&
    (!selectedCollateral || !selectedDebt || isNaN(+debtAmount) || +debtAmount <= 0 || wrongEvmNetwork || isLoading);

  return (
    <AnimatePresence>
      {selectedUser && (
        <motion.div
          onClick={onClose}
          initial={{ background: '#00000000' }}
          animate={{ background: '#00000073' }}
          exit={{ background: '#00000000' }}
          className="fixed bg-black flex items-center top-0 left-0 justify-center h-screen w-screen z-50"
        >
          <motion.div
            onClick={(e) => {
              e.stopPropagation();
            }}
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.1, opacity: 0 }}
            style={{
              transformOrigin: `${selectedFromPointX}px ${selectedFromPointY}px`,
              width: POPUP_WIDTH,
              maxWidth: '90vw',
              maxHeight: '80vh',
              overflow: 'auto',
            }}
          >
            <motion.div
              initial={{ height: internalPopupHeight }}
              animate={{ height: !data ? 300 : internalPopupHeight }}
              className="bg-meldred overflow-hidden text-meldwhite grow items-center flex pt-5 pb-8 px-5 flex-col relative z-[100] rounded-2xl"
            >
              <motion.div
                onClick={onClose}
                className={cn(
                  'ml-auto text-meldwhite/60 cursor-pointer transition-all hover:text-meldwhite',
                  loading && DISABLED_CLASS,
                )}
              >
                <CloseIcon />
              </motion.div>
              <p className="font-bold text-[32px] text-center">Liquidate Position</p>
              <AnimatePresence mode="wait">
                {data && (
                  <motion.div
                    key="1"
                    exit={{ scale: 0 }}
                    className="flex-col flex items-center w-full origin-[50%_20%]"
                  >
                    <AddressAndTools address={data?.address ?? ''} className="mx-auto" />
                    <div className="flex gap-7 justify-center mt-2 text-meldwhite/60 font-semibold text-sm">
                      <p>Borrowed {formatCurrency(data?.totalBorrowedFiat ?? 0)}</p>
                      <p>Collateral {formatCurrency(data?.totalSuppliedFiat ?? 0)}</p>
                    </div>
                    <Title text="Debt to Pay" />
                    <Carousel
                      loading={loading}
                      data={data?.borrowedAssets.map((asset) => (
                        <SmallCard
                          key={asset.contract}
                          balance={+asset.stable.fiatAmount || +asset.variable.fiatAmount}
                          tokenIcon={asset.tokenIcon}
                          tokenSymbol={asset.tokenSymbol}
                          selected={selectedDebt === asset.tokenSymbol}
                          onClick={() =>
                            setSelectedDebt((selected) => (selected === asset.tokenSymbol ? '' : asset.tokenSymbol))
                          }
                        />
                      ))}
                    />
                    <div className={cn('relative flex flex-col items-center w-[252px]', loading && DISABLED_CLASS)}>
                      <p
                        className={cn(
                          'font-bold mt-3 text-[18px] absolute top-[43px] left-[19px] bg-meldred z-10',
                          (!userDebtToken || (userDebtToken && !userToken)) && 'text-meldwhite/60',
                          userDebtToken && userToken && 'opacity-0',
                        )}
                      >
                        {!userDebtToken
                          ? 'Select a token'
                          : userDebtToken && !userToken
                            ? 'You do not have this token'
                            : null}
                      </p>
                      <Input
                        key={inputKey}
                        className="w-full mt-10"
                        selectedToken={selectedDebt ? { tokenSymbol: selectedDebt, decimals: 18 } : undefined}
                        decimalSeparator={numberFormatting.decimalSeparator}
                        thousandsSeparator={numberFormatting.thousandsSeparator}
                        handleAmountChange={(amount) => {
                          const percentage = (+amount * 100) / maxAmount;
                          setPercentage(percentage > 100 ? 100 : percentage);
                          setDebtAmount(amount);
                        }}
                        amount={debtAmount}
                        customMaxAmount={maxAmount.toString()}
                      />
                      <div className="flex items-center text-meldwhite/60 text-sm mt-1 font-bold px-1 justify-between w-full">
                        <div>{+debtAmount > 0 ? formatCurrency(debtDollarAmount) : '$0.00'}</div>
                        <motion.div
                          whileHover={{ scale: 1.05 }}
                          className="cursor-pointer"
                          onClick={() => setDebtAmount(maxAmount.toString())}
                        >
                          <span className="text-[13px]">MAX</span> {formatCryptoBalance(maxAmount)}
                        </motion.div>
                      </div>
                      <Slide
                        value={percentage}
                        className={cn('w-full mt-2 transition-opacity', loading && DISABLED_CLASS)}
                        max={userToken && userDebtToken ? 100 : 0.01}
                        onChange={(val) => {
                          setPercentage(val);
                          if (!val) {
                            setInputKey((oldState) => oldState + 1);
                            setDebtAmount('');
                          } else {
                            setDebtAmount(val === 100 ? maxAmount.toString() : (maxAmount * (val / 100)).toString());
                          }
                        }}
                      />
                    </div>
                    <Title text="Collateral to Receive" />
                    <Carousel
                      loading={loading}
                      data={data?.suppliedAssets.map((asset) => {
                        const balance =
                          simulationData && selectedCollateral === asset.tokenSymbol
                            ? +formatUnits(simulationData.result[1], asset.tokenDecimals)
                            : null;
                        return (
                          <SmallCard
                            balance={balance ?? 0}
                            usdBalance={balance ? balance * asset.tokenPrice : undefined}
                            tokenIcon={asset.tokenIcon}
                            tokenSymbol={asset.tokenSymbol}
                            selected={selectedCollateral === asset.tokenSymbol}
                            onClick={() =>
                              setSelectedCollateral((selected) =>
                                selected === asset.tokenSymbol ? '' : asset.tokenSymbol,
                              )
                            }
                          />
                        );
                      })}
                    />
                    <Button
                      onClick={onConfirm}
                      disabled={buttonDisabled}
                      className={cn(
                        'bg-meldwhite flex gap-2 items-center justify-center shrink-0 text-meldblack uppercase font-bold h-[50px] w-[230px] rounded-lg mt-9',
                        (buttonDisabled || loading || isLoading) && 'pointer-events-none opacity-60',
                        isLoading && 'animate-pulse',
                      )}
                    >
                      {liquidationSuccess !== null ? (
                        'Go Again'
                      ) : loading && mustApprove ? (
                        <>
                          Approving <AnimatedEllipsis className="relative -top-[2px]" />
                        </>
                      ) : mustApprove ? (
                        <>
                          <div>Approve</div>
                          <div className="text-meldblack/60 text-xs">
                            {approveEstimationData
                              ? formatCryptoBalance(
                                  formatUnits(
                                    approveEstimationData *
                                      (estimateFeePerGas?.maxFeePerGas ?? 0n) *
                                      (estimateFeePerGas?.maxPriorityFeePerGas ?? 0n),
                                    18,
                                  ).toString(),
                                  'gMELD',
                                  true,
                                )
                              : null}
                          </div>
                        </>
                      ) : loading ? (
                        <>
                          Confirming <AnimatedEllipsis className="relative -top-[2px]" />
                        </>
                      ) : (
                        <>
                          <div>Confirm</div>{' '}
                          <div className="text-meldblack/60 text-xs">
                            {liquidationEstimationData
                              ? formatCryptoBalance(
                                  formatUnits(
                                    liquidationEstimationData *
                                      (estimateFeePerGas?.maxFeePerGas ?? 0n) *
                                      (estimateFeePerGas?.maxPriorityFeePerGas ?? 0n),
                                    18,
                                  ).toString(),
                                  'gMELD',
                                  true,
                                )
                              : null}
                          </div>
                        </>
                      )}
                    </Button>
                    {liquidationSuccess !== null && (
                      <p className="text-xs uppercase flex gap-2 font-bold mt-2">
                        <span className={cn(liquidationSuccess && 'text-meldgreen')}>
                          {liquidationSuccess ? 'Success' : 'Reverted'}
                        </span>{' '}
                        <a
                          href={convertEVMHashToLink(liquidationHashRef.current as string, MELD_NETWORK)}
                          target="_blank"
                          className="underline text-meldwhite/60 cursor-pointer"
                        >
                          Hash
                        </a>
                      </p>
                    )}
                    <ErrorMessage className="absolute -bottom-3" onError={handleErrorFlag} />
                  </motion.div>
                )}
                {!data && (
                  <motion.div
                    key="2"
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    className="flex grow"
                  >
                    <div className="flex flex-col items-center grow justify-between">
                      <div />
                      <div className=" uppercase font-semibold pt-5">User liquidated</div>
                      <Button
                        onClick={onClose}
                        className={cn(
                          'bg-meldwhite shrink-0 text-meldblack uppercase font-bold h-[50px] w-[230px] rounded-lg mt-9',
                        )}
                      >
                        Go Back
                      </Button>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
