import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { WagmiProvider } from 'wagmi';

import { queryClient } from '@api/query-client.ts';

import { wagmiAdapter } from '@components/wagmi-provider/constants.ts';

import { checkEnvVars } from '@utils/environments-helper.ts';

import App from './App.tsx';
import './index.css';
import './locales';

checkEnvVars();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <WagmiProvider config={wagmiAdapter.wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>,
);
