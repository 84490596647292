import { devtools } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { appSliceType, createAppSlice } from './app-slice';

export type StoreType = appSliceType;

export const useStore = createWithEqualityFn<StoreType>()(
  devtools(
    (...a) => ({
      ...createAppSlice(...a),
    }),
    { trace: true },
  ),
  shallow,
);
