import { useAppKitState } from '@reown/appkit/react';
import { useCallback, useEffect, useRef } from 'react';

export const useOverrideWcColors = () => {
  const { open: isOpen } = useAppKitState();

  useEffect(() => {
    // override some web3modal colors
    document.documentElement.style.setProperty('--wui-color-fg-100', '#ffffff');
    document.documentElement.style.setProperty('--wui-color-gray-glass-002', '#ffffff1f');
    document.documentElement.style.setProperty('--wui-color-gray-glass-005', '#ffffff3f');
    document.documentElement.style.setProperty('--wui-color-gray-glass-010', '#ffffff78');
    document.documentElement.style.setProperty('--wui-color-fg-200', '#ffffffc9');
    document.documentElement.style.setProperty('--wui-color-error-100', 'white');
    document.documentElement.style.setProperty('--wui-color-fg-275', '#ffffff94');
    document.documentElement.style.setProperty('--wui-color-bg-200', '#ffffff94');
    document.documentElement.style.setProperty('--wui-color-success-100', '#ffffff');
    document.documentElement.style.setProperty('--wui-color-fg-150', '#ffffff');
  }, []);

  /**
   * Update certain UI elements of their popup which don't look good after making the background red-ish
   */
  const checkIsOpen = useCallback(() => {
    const wcModal = document.querySelector('w3m-modal');
    if (wcModal) {
      const wuiCard = wcModal.shadowRoot?.querySelector('wui-card');

      // target view (means our UI should be displaying)
      const w3mConnectView = wuiCard?.querySelector('w3m-router')?.shadowRoot?.querySelector('w3m-connect-view');

      const containersInW3mConnectView = w3mConnectView?.shadowRoot?.querySelectorAll('wui-flex');
      const getStartedButton = containersInW3mConnectView?.[containersInW3mConnectView.length - 1]
        .querySelector('w3m-wallet-guide')
        ?.shadowRoot?.querySelector('wui-link')
        ?.shadowRoot?.querySelector('button');

      if (getStartedButton) {
        getStartedButton.style.backgroundColor = 'var(--wui-color-gray-glass-002)';
        getStartedButton.style.marginLeft = '8px';

        const textOfButton = getStartedButton.querySelector('wui-text')?.shadowRoot?.querySelector('slot');
        if (textOfButton) textOfButton.style.color = '#ffffff';
      }

      // if the web3modal is on screen keep checking for changes inside it
      if (wuiCard) setTimeout(() => checkIsOpen(), 10);
    }
  }, []);

  const checkIsOpenRef = useRef(checkIsOpen);

  useEffect(() => {
    checkIsOpenRef.current = checkIsOpen;
  }, [checkIsOpen]);

  useEffect(() => {
    if (isOpen) {
      checkIsOpenRef.current();
    }
  }, [isOpen]);
};
