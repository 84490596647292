import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { NETWORKS_LOCALSTORAGE_KEY, SUPPORTED_TOKENS_LOCALSTORAGE_KEY } from 'src/contants/localstorage';
import { shallow } from 'zustand/shallow';

import { useStore } from '@store/store';

import { LENDING_AND_BORROWING_GLOBAL, getGlobalLendingAndBorrowing } from '@api/lending-and-borrowing-contracts/query';
import { GET_LIQUIDATIONS, getLiquidationsQuery } from '@api/liquidations/query';
import {
  GET_AVAILABLE_TOKENS_QUERY_KEY,
  getAvailableTokensQuery,
} from '@api/meld-app/available-tokens/available-tokens-query';
import { AvailableToken } from '@api/meld-app/available-tokens/available-tokens.types';
import { getNetworksQuery } from '@api/meld-app/networks/networks-query';

import { parseAvailableTokens } from './utils/parse-available-tokens';
import { parseLiquidations } from './utils/parse-liquidations';
import { parseNetworks } from './utils/parse-networks';

/**
 * Fetches `/tokens` and `/networks` from the BE and pushes the data to our state and saves it in local storage.
 *
 * @remarks
 * - Adds a few properties to the tokens, that it gets from `/networks` and also processes the SVG icon URL to be used accross the app.
 * - If we already have data in storage then we initialise the app with that data relying on 'placeholderData' from react-query.
 * Which allows us to tell react-query "use this data while you're fetching the latest".
 */
export const useInitialBEData = () => {
  const setAvailableTokens = useStore((state) => state.setAvailableTokens);
  const setNetworks = useStore((state) => state.setNetworks);
  const setAppData = useStore((state) => state.setAppData);
  const setLendingPoolContractAddress = useStore((state) => state.setLendingPoolContractAddress);

  const availableTokens = useStore((state) => state.availableTokens, shallow);

  const [hasFetched, setHasFetched] = useState(false);

  const { data: lendingPoolContractAddress } = useQuery(
    [LENDING_AND_BORROWING_GLOBAL],
    async () => {
      const lendingAndBorrowingGlobal = await getGlobalLendingAndBorrowing();

      return lendingAndBorrowingGlobal.addresses.lendingPool;
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setHasFetched(true);
      },
      refetchOnMount: false,
      keepPreviousData: true,
    },
  );

  const { data: liquidationsData } = useQuery(
    [GET_LIQUIDATIONS],
    async () => {
      const liquidationsData = await getLiquidationsQuery();

      const liquidations = parseLiquidations({
        liquidations: liquidationsData,
        availableTokens: availableTokens as AvailableToken[],
      });

      return liquidations;
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setHasFetched(true);
      },
      refetchOnMount: false,
      keepPreviousData: true,
      enabled: !!hasFetched && !!availableTokens?.length,
    },
  );

  useEffect(() => {
    if (lendingPoolContractAddress) setLendingPoolContractAddress(lendingPoolContractAddress);
  }, [lendingPoolContractAddress, setLendingPoolContractAddress]);

  useEffect(() => {
    if (liquidationsData) setAppData({ liquidations: liquidationsData });
  }, [liquidationsData, setAppData]);

  const { data, isLoading } = useQuery(
    [GET_AVAILABLE_TOKENS_QUERY_KEY],
    async () => {
      const [networksData, tokensData] = await Promise.all([getNetworksQuery(), getAvailableTokensQuery()]);

      const networks = parseNetworks(networksData);

      const availableTokens = parseAvailableTokens(tokensData, networks);

      return { availableTokens, networks };
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setHasFetched(true);
      },
      refetchOnMount: false,
    },
  );

  useEffect(() => {
    if (data && data.availableTokens && data.networks) {
      // store available tokens in local storage
      localStorage.setItem(SUPPORTED_TOKENS_LOCALSTORAGE_KEY, JSON.stringify(data.availableTokens));
      setAvailableTokens(data.availableTokens);

      // store networks in local storage
      localStorage.setItem(NETWORKS_LOCALSTORAGE_KEY, JSON.stringify(data.networks));
      setNetworks(data.networks);
    }
  }, [data, setAvailableTokens, setNetworks, isLoading, hasFetched, setAppData]);

  return !liquidationsData;
};
