import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { WalletToken } from '@typings/wallet-asset.types';
import getUserLocale from 'get-user-locale';
import { useEffect, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import { useStore } from '@store/store';

import { WCPopupAddon } from '@components/connect-button/wc-popup-addon';
import { Layout } from '@components/layout';
import { Liquidations } from '@components/liquidations';
import { Popup } from '@components/popup';
import { TabSelector } from '@components/tab-selector';
import { ExtCardanoWallets } from '@components/with-cardano-external-wallets';

import { useInitialBEData } from '@hooks/use-initial-be-data/use-initial-be-data';
import { useOverrideWcColors } from '@hooks/use-override-wc-colors';
import { useUserPastHistory } from '@hooks/use-user-past-history';
import { useWalletTokens } from '@hooks/use-wallet-tokens';
import { useWalletsRegisterAndSynced } from '@hooks/use-wallets-register-and-synced';

import { getDecimalSeparator } from '@utils/format-currency/get-decimals-separator';

import { MUI_THEME } from './theme';

function App() {
  const userTokens = useStore((state) => state.userTokens, shallow);
  const selectedUser = useStore((state) => state.appData.selectedUser);

  const setNumberFormatting = useStore((state) => state.setNumberFormatting);
  const liquidations = useStore((state) => state.appData.liquidations, shallow);

  // get the users decimal separator depending on their browser settings
  useEffect(() => {
    const decimalSeparator = getDecimalSeparator(getUserLocale());
    setNumberFormatting({ decimalSeparator, thousandsSeparator: decimalSeparator === '.' ? ',' : '.' });
  }, [setNumberFormatting]);

  useOverrideWcColors();
  useInitialBEData();
  useWalletsRegisterAndSynced();
  useWalletTokens();
  useUserPastHistory();

  const userTokensByContract = useMemo(
    () =>
      userTokens.reduce<Record<string, WalletToken>>((prev, curr) => {
        prev[curr.contract] = curr;
        return prev;
      }, {}),
    [userTokens],
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={MUI_THEME}>
        <ExtCardanoWallets />
        <Layout>
          <TabSelector
            className="max-w-[90vw] w-[400px] mt-9"
            options={[{ data: 'users to liquidate' }, { data: 'past liquidations', disabled: true }]}
            selected={0}
          />
          <Popup />
          <Liquidations data={liquidations} userTokensByContract={userTokensByContract} selectedUser={selectedUser} />
          <WCPopupAddon />
        </Layout>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
