export enum NetworkChainType {
  EVM = 'evm',
  CARDANO = 'cardano',
}

export type NetworkBE = {
  chainId: number;
  chainType: NetworkChainType;
  darkIcon: string; //ex: '/static/networks/PolygonDark.svg'
  decimals: number;
  defaultTokenSymbol: string;
  explorerUrl: string;
  lightIcon: string;
  name: string;
  publicRpcUrls: Array<string>; // can have both https and ws protocol
};

export type Network = {
  id: number;
  name: string;
  nativeCurrency: { name: string; symbol: string; decimals: number };
  rpcUrls: { default: { http: string[] } };
  blockExplorers: { default: { name: string; url: string; apiUrl?: string } };
  contracts: {
    ensRegistry?: { address: string };
    ensUniversalResolver?: { address: string; blockCreated: number };
    multicall3?: { address: string; blockCreated: number };
  };
  lightIcon: string;
  darkIcon: string;
  chainType: NetworkChainType;
};

export type NetworksResponse = Array<NetworkBE>;
