import { useMutation } from '@tanstack/react-query';
import { SupportedWalletBE } from '@typings/wallet-asset.types';
import { AxiosError } from 'axios';
import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

import {
  GetWalletRegistrationQueryType,
  OriginTypes,
  PostWalletRegistrationQueryType,
} from '@api/meld-app/wallet-registration/wallet-registration-query.types';

// TODO - refactor this to use the new GET and POST /wallet
type props = {
  notBroadcastedAddress: string;
  postWalletRegistrationQuery: (
    address: string,
    wallet: SupportedWalletBE,
    origin?: OriginTypes,
  ) => Promise<PostWalletRegistrationQueryType>;
  getWalletRegistrationQuery: (address: string[]) => Promise<GetWalletRegistrationQueryType>;
  setAddressRegistered: (flag: boolean) => void;
  setWalletAddress: (address: string) => void;
  walletSyncedQuery: (address: string) => Promise<{ ready: boolean; address: string }>;
  walletName: string;
  walletType: SupportedWalletBE;
};

export const useWalletRegisterAndSynced = ({
  notBroadcastedAddress,
  postWalletRegistrationQuery,
  getWalletRegistrationQuery,
  setAddressRegistered,
  setWalletAddress,
  walletSyncedQuery,
  walletType,
}: props) => {
  const walletAddressSyncing = useRef<string | null>(null);
  const toastIdSyncingMessage = useRef<number | string | null>(null);
  const isSyncing = useRef<boolean>(false);

  const removeSyncingToast = () => {
    if (toastIdSyncingMessage.current) {
      toast.done(toastIdSyncingMessage.current as string | number);
      toastIdSyncingMessage.current = null;
    }
  };

  const { mutate: mutateIsSynced } = useMutation(() => walletSyncedQuery(notBroadcastedAddress), {
    retry: true,
    retryDelay: 5000,
    onSuccess: (data) => {
      try {
        if (data.address === notBroadcastedAddress.toLowerCase()) {
          setWalletAddress(notBroadcastedAddress);
          isSyncing.current = false;
        }
      } catch (err) {
        console.error(err);
      }
    },
  });

  const onRegistrationSuccess = () => {
    walletAddressSyncing.current = notBroadcastedAddress;
    setAddressRegistered(true);

    if (!isSyncing.current) {
      isSyncing.current = true;
      mutateIsSynced();
    }
  };

  const { mutate: mutatePostWalletRegistration } = useMutation(
    () => postWalletRegistrationQuery(notBroadcastedAddress, walletType),
    {
      //BE returns a 200 if the address has already been registered
      onSuccess: () => onRegistrationSuccess(),
      onError: (error: AxiosError<{ error: string }>) => {
        const errorMsg = error?.response?.data.error || '';
        if (errorMsg.startsWith('wallet already registered')) {
          onRegistrationSuccess();
        }
      },
    },
  );

  const { mutate: mutateGetWalletRegistration } = useMutation(
    () => getWalletRegistrationQuery([notBroadcastedAddress]),
    {
      //BE returns a 200 if the address has already been registered
      onSuccess: (data) => {
        // wallet is already registered
        if (data.length) {
          onRegistrationSuccess();
        }
        // register wallet
        else {
          mutatePostWalletRegistration();
        }
      },
      onError: (error: AxiosError<{ error: string }>) => {
        const errorMsg = error?.response?.data.error || '';
        if (errorMsg.startsWith('wallet already registered')) {
          onRegistrationSuccess();
        }
      },
    },
  );

  // Register address with the BE if it changes
  useEffect(() => {
    if (notBroadcastedAddress) {
      mutateGetWalletRegistration();
    }
    // address disconnected
    if (!notBroadcastedAddress || notBroadcastedAddress === '') {
      isSyncing.current = false;
      removeSyncingToast();
    }
  }, [mutateGetWalletRegistration, notBroadcastedAddress]);
};
