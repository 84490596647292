import { motion } from 'framer-motion';
import { memo, useEffect, useMemo, useState } from 'react';
import { useSwitchChain } from 'wagmi';

import { useStore } from '@store/store';

import { useUnsupportedExtNetwork } from '@hooks/use-unsupported-ext-network';

import { cn } from '@utils/cn';

const ErrorTypes = {
  WRONG_CARDANO_NETWORK: 'WRONG_CARDANO_NETWORK',
  WRONG_EVM_NETWORK: 'WRONG_EVM_NETWORK',
  CARDANO_NOT_SUPPORTED: 'CARDANO_NOT_SUPPORTED',
  NO_CONNECTED_WALLET: 'NO_CONNECTED_WALLET',
} as const;

type WrongCardanoNetworkType = { type: typeof ErrorTypes.WRONG_CARDANO_NETWORK; walletNameToUse: string };
type WrongEvmNetworkType = { type: typeof ErrorTypes.WRONG_EVM_NETWORK; walletNameToUse: string };
type CardanoNotSupportedType = { type: typeof ErrorTypes.CARDANO_NOT_SUPPORTED };
type NoConnectedWalletType = { type: typeof ErrorTypes.NO_CONNECTED_WALLET };

type Error = { showingError: boolean } & (
  | CardanoNotSupportedType
  | WrongCardanoNetworkType
  | WrongEvmNetworkType
  | NoConnectedWalletType
);

export const ErrorMessage = memo(({ className, onError }: { className?: string; onError: (flag: boolean) => void }) => {
  const [hasError, setHasError] = useState<Error | null>(null);
  const { switchChain } = useSwitchChain();
  const { wrongEvmNetwork, correctChainId } = useUnsupportedExtNetwork();
  const evmWalletName = useStore((state) => state.evmData.evmWalletName);
  const evmAddress = useStore((state) => state.evmData.evmAddress);

  const error = useMemo(() => {
    if (wrongEvmNetwork) return { type: ErrorTypes.WRONG_EVM_NETWORK, walletNameToUse: evmWalletName as string };
    if (!evmAddress) return { type: ErrorTypes.NO_CONNECTED_WALLET };
    return null;
  }, [wrongEvmNetwork, evmWalletName, evmAddress]);

  useEffect(() => {
    if (error) {
      setHasError({ ...error, showingError: true } as Error);
      onError(true);
    } else {
      onError(false);
      setHasError((oldError) => {
        return oldError ? { ...oldError, showingError: false } : null;
      });
    }
  }, [error, onError]);

  const getErrorMessage = () => {
    switch (hasError?.type) {
      case ErrorTypes.WRONG_EVM_NETWORK:
      case ErrorTypes.WRONG_CARDANO_NETWORK:
        return (
          <>
            Please{' '}
            <span
              onClick={() => switchChain && switchChain({ chainId: correctChainId as number })}
              className={cn(
                hasError?.type === ErrorTypes.WRONG_EVM_NETWORK &&
                  "relative inline-block cursor-pointer after:block after:h-[1px] after:w-full after:bg-meldred after:content-['_']",
              )}
            >
              switch to the correct network
            </span>{' '}
            in {hasError.walletNameToUse}.
          </>
        );
      case ErrorTypes.NO_CONNECTED_WALLET:
        return <>Connect a wallet to proceed.</>;
      default:
        return '';
    }
  };

  return (
    <motion.div
      initial={{ height: 0 }}
      animate={{ height: hasError?.showingError ? 60 : 0 }}
      className={cn(
        'm-0 mx-auto mb-3 box-border overflow-hidden flex h-[60px] w-full items-center justify-center bg-meldblack px-4 text-center',
        className,
      )}
    >
      <p className={cn('font-semibold text-[12px] leading-[18px] text-meldred')}>{getErrorMessage()}</p>
    </motion.div>
  );
});
