import { WagmiAdapter } from '@reown/appkit-adapter-wagmi';
import { defineChain } from '@reown/appkit/networks';
import { createAppKit } from '@reown/appkit/react';

import { isNotProdEnv } from '@utils/environments-helper';

const DEV_CHAINS = [
  defineChain({
    id: 222000222,
    name: 'kanazawa',
    nativeCurrency: { decimals: 18, name: 'gMELD', symbol: 'gMELD' },
    rpcUrls: {
      default: { http: ['https://subnets.avax.network/meld/testnet/rpc'] },
    },
    blockExplorers: { default: { name: '', url: 'https://testnet.meldscan.io' } },
    chainNamespace: 'eip155',
    caipNetworkId: 'eip155:222000222',
  }),
];

const PROD_CHAINS = [
  defineChain({
    id: 333000333,
    name: 'meld',
    nativeCurrency: { decimals: 18, name: 'gMELD', symbol: 'gMELD' },
    rpcUrls: {
      public: { http: ['https://rpc-1.meld.com'] },
      default: { http: ['https://rpc-1.meld.com'] },
    },
    blockExplorers: { default: { name: '', url: 'https://meldscan.io' } },
    chainNamespace: 'eip155',
    caipNetworkId: 'eip155:333000333',
  }),
];

export const WAGMI_CHAINS = isNotProdEnv ? DEV_CHAINS : PROD_CHAINS;

// 1. Get projectId at https://cloud.walletconnect.com
export const WC_PROJECT_ID = import.meta.env.VITE_APP_WALLET_CONNECT_PROJECT_ID ?? '';

// 2. Create wagmiConfig
const metadata = {
  name: 'MELD Product', // TODO Update
  description: 'TBD',
  url: 'https://meld.com',
  icons: ['https://meld-assets.fra1.cdn.digitaloceanspaces.com/meld_logo_sm.png'],
};

export const wagmiAdapter = new WagmiAdapter({
  networks: WAGMI_CHAINS,
  projectId: WC_PROJECT_ID,
});

createAppKit({
  adapters: [wagmiAdapter],
  // @ts-expect-error 2322
  networks: WAGMI_CHAINS,
  metadata,
  themeVariables: {
    '--w3m-font-family': 'BRHendrixSemiBold',
    '--w3m-accent': '#FFFFFF',
    '--w3m-color-mix': '#EB0046',
    '--w3m-color-mix-strength': 100,
    '--w3m-border-radius-master': '3px',
  },
  projectId: WC_PROJECT_ID,
  features: {
    analytics: true,
  },
});
