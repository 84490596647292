import { useMemo } from 'react';
import { MELD_NETWORK } from 'src/contants/meld';

import { useStore } from '@store/store';

import { evmNetworks } from '@utils/networks/networks';

export const useEvmNetwork = () => {
  const chainId = useStore((state) => state.evmData.evmConnectedChainId);

  const network = useMemo(() => evmNetworks.find((network) => network.id === chainId)?.name ?? MELD_NETWORK, [chainId]);

  return network;
};
