import { useMemo } from 'react';
import { MELD_NETWORK } from 'src/contants/meld';
import { shallow } from 'zustand/shallow';

import { useStore } from '@store/store';

export const useUnsupportedExtNetwork = () => {
  const networks = useStore((state) => state.networks, shallow);
  const chainId = useStore((state) => state.evmData.evmConnectedChainId);
  const wrongCardanoNetwork = useStore((state) => state.cardanoData.cardanoWrongNetwork);

  const { wrongEvmNetwork, correctChainId } = useMemo(() => {
    const correctChainId = networks?.[MELD_NETWORK]?.id;

    return {
      wrongEvmNetwork: Boolean(chainId && chainId !== correctChainId),
      correctChainId,
    };
  }, [chainId, networks]);

  return {
    wrongEvmNetwork,
    wrongCardanoNetwork,
    correctChainId,
  };
};
