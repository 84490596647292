import { Button } from '@mui/material';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';

import { cn } from '@utils/cn';

type Props = {
  options: Array<{ data: ReactNode; disabled?: boolean }>;
  selected: number;
  className?: string;
};

export const TabSelector = ({ options, selected, className }: Props) => {
  return (
    <div
      className={cn(
        'flex border border-white border-solid h-[50px] items-center justify-between px-6 rounded-lg shadow-sm',
        className,
      )}
    >
      {options.map(({ data, disabled }, index) => (
        <motion.div key={index} whileHover={{ scale: disabled ? 1 : 1.02 }} className="h-full">
          <Button
            tabIndex={1}
            className={cn(
              'font-semibold hover:bg-transparent uppercase h-full transition-colors text-xs md:text-base text-meldwhite/60 cursor-pointer',
              selected === index && 'text-white',
              disabled && 'cursor-not-allowed',
            )}
          >
            <div className={cn(disabled && 'pointer-events-none')}>{data}</div>
          </Button>
        </motion.div>
      ))}
    </div>
  );
};
