import { AvailableToken } from '@api/meld-app/available-tokens/available-tokens.types';

import { LiquidationsResponseType, LiquidationsType } from './../../../api/liquidations/types';

/**
 * Add token icons
 */
export const parseLiquidations = ({
  liquidations,
  availableTokens,
}: {
  liquidations: LiquidationsResponseType;
  availableTokens: Array<AvailableToken>;
}) =>
  liquidations
    .map((data) => {
      let totalBorrowedFiat = 0;
      let totalSuppliedFiat = 0;

      const updatedData = {
        ...data,
        borrowedAssets: data.borrowedAssets.map((asset) => {
          if (+asset.stable.fiatAmount > 0) totalBorrowedFiat += +asset.stable.fiatAmount;
          if (+asset.variable.fiatAmount > 0) totalBorrowedFiat += +asset.variable.fiatAmount;
          const availableToken = availableTokens.find((token) => token.contract === asset.contract);

          return {
            ...asset,
            tokenIcon: availableToken?.tokenIcon ?? '',
            tokenSymbol: availableToken?.symbol ?? '',
            tokenDecimals: availableToken?.decimals,
            tokenPrice: +(availableToken?.price ?? 0),
          };
        }),
        suppliedAssets: data.suppliedAssets.map((asset) => {
          if (asset.isCollateral) totalSuppliedFiat += +asset.fiatAmount;
          const availableToken = availableTokens.find((token) => token.contract === asset.contract);

          return {
            ...asset,
            tokenIcon: availableToken?.tokenIcon ?? '',
            tokenSymbol: availableToken?.symbol ?? '',
            tokenDecimals: availableToken?.decimals,
            tokenPrice: +(availableToken?.price ?? 0),
          };
        }),
      };

      return { ...updatedData, totalBorrowedFiat, totalSuppliedFiat };
    })
    .sort((a, b) => b.totalBorrowedFiat - a.totalBorrowedFiat) as LiquidationsType;
