import { motion } from 'framer-motion';
import isMobile from 'is-mobile';
import { ReactNode } from 'react';

import { useLoadedInitialBeData } from '@hooks/use-loaded-initial-be-data';

import { cn } from '@utils/cn';

import { Header } from './header';

type Props = {
  children: ReactNode;
};

const _isMobile = isMobile();

export const Layout = ({ children }: Props) => {
  const loadedInitialBeData = useLoadedInitialBeData();

  return (
    <div className="p-6 md:p-[50px]">
      <Header />
      <motion.div
        initial={{ opacity: 0 }}
        animate={loadedInitialBeData ? { opacity: 1 } : { opacity: 0 }}
        transition={{ delay: _isMobile ? 1.2 : 2.5 }}
        className={cn(
          'mx-auto relative z-0 mt-[58px] flex w-full max-w-[1180px] flex-col items-center px-0 opacity-0 md:w-auto md:px-[78px] min-[1370px]:px-0',
        )}
      >
        {children}
      </motion.div>
    </div>
  );
};
