import { createPublicClient, defineChain, http } from 'viem';

import { Network } from '@api/meld-app/networks/networks.types';

const connectToRPC = (rpcUrl: string, chain: Network) => {
  const client = createPublicClient({
    chain: defineChain(chain),
    transport: http(rpcUrl),
  });

  return client;
};

const evmProviderUtils = {
  connectToRPC,
};

export default evmProviderUtils;
