import { NetworkChainType } from '@api/meld-app/networks/networks.types';

import { SupportedWallet } from './wallet';

export type WalletToken = {
  amount: string; // amount formatted, not in WEI
  color: string;
  contract: string;
  decimals: number;
  description: string;
  fiatAmount: string;
  icon: string; // path to SVG
  isEvm: boolean;
  isNative: boolean;
  isStableCoin: boolean;
  name: string;
  network: string;
  walletAddress: string;
  price: string;
  slug: string;
  symbol: string;
  tokenId: string;
  // props below are added by the FE
  inShortAddress?: boolean;
  wallet: SupportedWallet;
  chainName: string;
  chainType: NetworkChainType;
  usdTotalPrice: number;
};

export enum SupportedWalletBE {
  CARDANO = 'cardano',
  EVM = 'evm',
}
