import { motion } from 'framer-motion';
import { useRef, useState } from 'react';
import { IS_INTERNAL_APP } from 'src/contants/app';

import { useLoadedInitialBeData } from '@hooks/use-loaded-initial-be-data';

import { cn } from '@utils/cn';

import logo from '../assets/logo.svg';
import meld from '../assets/meld.svg';

const APP_NAME = 'LIQUIDATIONS';

export const Logo = () => {
  const [gradientStart, setGradientStart] = useState(false);
  const [textStyleActive, setTextStyleActive] = useState(false);
  const loopRef = useRef(false);
  const [hideCopy, setHideCopy] = useState(false);
  const [gradientFinished, setGradientFinished] = useState(false);

  const loadedInitialBeData = useLoadedInitialBeData();

  return (
    <div
      className={cn(
        'flex items-center gap-[10px]',
        // pulse if data has not loaded yet
        textStyleActive && gradientFinished && !loadedInitialBeData && 'animate-pulse',
      )}
    >
      <motion.img
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2 }}
        onUpdate={(e) => {
          if (e.opacity === 1) setTextStyleActive(true);
        }}
        src={logo}
        className="w-[30px] drop-shadow-[0px_4px_30px_rgba(0,_0,_0,_0.25)] md:w-[60px]"
        alt="meld logo"
      />
      <motion.img
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2 }}
        onUpdate={(e) => {
          if (e.opacity === 1) setTextStyleActive(true);
        }}
        src={meld}
        className="w-16 md:w-auto"
        alt="meld logo"
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1, duration: 0.5 }}
        className={'relative -top-[14px] hidden opacity-0 min-[400px]:block [&_p]:m-0'}
        onUpdate={(e) => {
          if (+e.opacity > 0.8 && !loopRef.current) {
            loopRef.current = true;
            setGradientStart(true);
          }
        }}
      >
        <span
          className={cn(
            'absolute top-[3px] font-light text-base text-white opacity-100 md:top-[2px] md:text-[27px]',
            hideCopy && 'opacity-0',
          )}
        >
          {APP_NAME}
        </span>
        <div className="absolute top-[3px] flex flex-row md:top-[2px]">
          <span
            onAnimationIteration={() => {
              setGradientStart(false);
              setHideCopy(true);
              setGradientFinished(true);
            }}
            className={cn('font-light text-base text-white opacity-100  md:text-[27px]', gradientStart && 'logoText')}
          >
            <div>{APP_NAME}</div>
          </span>
        </div>
        {IS_INTERNAL_APP && (
          <span
            className={cn(
              'absolute top-[26px] -left-[107px] tracking-wide font-black text-base text-white/60 opacity-100 text-[11px] uppercase',
            )}
          >
            Internal
          </span>
        )}
      </motion.div>
    </div>
  );
};
