import { useQuery } from '@tanstack/react-query';
import { WalletToken } from '@typings/wallet-asset.types';
import { useEffect, useMemo } from 'react';
import { MELD_NETWORK } from 'src/contants/meld';
import { formatUnits } from 'viem';
import { shallow } from 'zustand/shallow';

import { useStore } from '@store/store';

import {
  GET_EXT_CARDANO_WALLET_TOKENS_QUERY_KEY,
  GET_METAMASK_WALLET_TOKENS_QUERY_KEY,
  getExtCardanoWalletTokensQuery,
  getMetamaskWalletTokensQuery,
} from '@api/meld-app/wallet-tokens/wallet-tokens-query';

export const useWalletTokens = () => {
  const { cardanoAddress } = useStore((state) => state.cardanoData, shallow);
  const evmAddress = useStore((state) => state.evmData.evmAddress);
  const setUserTokens = useStore((state) => state.setUserTokens);

  const {
    isLoading: isLoadingEvm,
    fetchStatus: fetchStatusEvm,
    data: dataEvm,
    isRefetching: isRefetchingEvm,
  } = useQuery(
    [GET_METAMASK_WALLET_TOKENS_QUERY_KEY, evmAddress],
    () => getMetamaskWalletTokensQuery(evmAddress as string),
    {
      refetchOnWindowFocus: false,
      enabled: !!evmAddress,
      refetchOnMount: false,
    },
  );

  const {
    isLoading: isLoadingCardano,
    fetchStatus: fetchStatusCardano,
    isRefetching: isRefetchingCardano,
    data: dataCardano,
  } = useQuery(
    [GET_EXT_CARDANO_WALLET_TOKENS_QUERY_KEY, cardanoAddress],
    () => getExtCardanoWalletTokensQuery(cardanoAddress as string),
    {
      refetchOnWindowFocus: false,
      enabled: !!cardanoAddress,
      refetchOnMount: false,
    },
  );

  const tokenData = useMemo(() => {
    return (dataEvm ?? [])
      .map((a) => ({ ...a, amount: formatUnits(BigInt(a.amount), a.decimals) }))
      .filter((a) => a.network === MELD_NETWORK)
      .concat((dataCardano ?? []).map((a) => ({ ...a, amount: formatUnits(BigInt(a.amount), a.decimals) })));
  }, [dataEvm, dataCardano]);

  useEffect(() => {
    setUserTokens(tokenData as Array<WalletToken>);
  }, [tokenData, setUserTokens]);

  const _isLoadingEvm = isLoadingEvm && fetchStatusEvm !== 'idle';
  const _isLoadingCardano = isLoadingCardano && fetchStatusCardano !== 'idle';

  return {
    isLoadingEvm: _isLoadingEvm,
    fetchStatusEvm,
    dataEvm,
    isRefetchingEvm,
    isLoadingCardano: _isLoadingCardano,
    dataCardano,
    isRefetchingCardano,
    tokenData,
    isRefetching: isRefetchingEvm || isRefetchingCardano,
    isLoading: _isLoadingEvm || _isLoadingCardano,
  };
};
