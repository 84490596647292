import { evmNetworks } from '../networks/networks';
import evmProviderUtils from './evmProviderUtils';

/**
 * Fetches balances of all EVM networks we support
 * @param walletAddress evm wallet address
 * @remarks
 * This is currently needed because upon connecting an external wallet the BE is not instantly ready
 * with native EVM balances, like they are with balances of ERC-20 tokens.
 */
const getNativeTokenBalances = async (walletAddress: string) =>
  await Promise.all(
    evmNetworks.map(async (network) => {
      const provider = evmProviderUtils.connectToRPC(network.rpcUrls.default.http[0], network);
      return {
        balance: (await provider.getBalance({ address: walletAddress })).toString(),
        tokenSymbol: network.nativeCurrency.symbol,
      };
    }),
  );

const evmWalletUtils = {
  getNativeTokenBalances,
};

export default evmWalletUtils;
