import { motion } from 'framer-motion';
import isMobile from 'is-mobile';
import { useCallback, useState } from 'react';
import { IS_CARDANO_ENABLED } from 'src/contants/app';
import { CARDANO_NETWORK } from 'src/contants/cardano';
import { shallow } from 'zustand/shallow';

import { useStore } from '@store/store';

import { NetworkChainType } from '@api/meld-app/networks/networks.types';

import { useCardanoConnection } from '@components/connect-button/use-cardano-connection';
import { useEvmConnection } from '@components/connect-button/use-evm-connection';
import { ConnectButton } from '@components/user-menu/connect-button';
import { WalletMenu } from '@components/user-menu/wallet-menu';

import { useIsMobile } from '@hooks/use-is-mobile';
import { useLoadedInitialBeData } from '@hooks/use-loaded-initial-be-data';

import { cn } from '@utils/cn';
import { networks } from '@utils/networks/networks';

import walletIcon from '../../assets/wallet.svg';
import { CardanoPopup } from './cardano-popup';
import { PickChainPopup } from './pick-chain-popup';

const _isMobile = isMobile();

export const ConnectButtonMenu = () => {
  const [state, _setState] = useState({
    runningOperation: false,
    data: {
      cardano: [
        {
          fromImgSrc: 'https://tracking-api-dev.meldlabs.dev/proxy/assets/networks/CardanoDark.svg',
          toImgSrc: 'https://tracking-api-dev.meldlabs.dev/proxy/assets/networks/MeldDark.svg',
          formattedAmount: '12 tADA',
          date: '2024-10-14T12:05:56Z',
          explorerUrl:
            'https://testnet.meldscan.io/tx/0x857d65fd9bfadd3c9d86f0f22d0268aa746008c385311345261434de00a8652a',
          status: 'Finalized',
        },
      ],
      evm: [],
    },
  });
  const {
    runningOperation,
    data: { cardano: dataCardano, evm: dataEvm },
  } = state;

  const loadedInitialBeData = useLoadedInitialBeData();
  const isMobile = useIsMobile();

  const [chain, setChain] = useState<'cardano' | 'evm' | null>(null);
  const [walletMenuOpen, setWalletMenuOpen] = useState<HTMLElement | null>(null);

  const externalWallet = useStore((state) => state.externalWalletData, shallow);

  const {
    address: cardanoAddress,
    setOpen: cardanoSetOpen,
    disconnect: cardanoDisconnect,
    isConnected: cardanoIsConnected,
    isConnecting: cardanoIsConnecting,
  } = useCardanoConnection();

  const {
    address: evmAddress,
    open: evmOpen,
    disconnect: evmDisconnect,
    isConnected: evmIsConnected,
    isConnecting: evmIsConnecting,
    network,
  } = useEvmConnection();

  const onWalletMenuClosed = useCallback(() => {
    setWalletMenuOpen(null);
  }, []);

  const onTriggerMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setWalletMenuOpen(event.currentTarget);
  }, []);

  const onCtaClicked = useCallback(() => {}, []);

  return (
    <div className={cn(!loadedInitialBeData && 'pointer-events-none opacity-0')}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={loadedInitialBeData ? { opacity: 1 } : { opacity: 0 }}
        transition={{ delay: _isMobile ? 1.2 : 2.5 }}
        className={cn((runningOperation || !loadedInitialBeData) && 'pointer-events-none')}
      >
        <ConnectButton
          cardanoIcon={externalWallet.chainType === NetworkChainType.CARDANO ? walletIcon : undefined}
          evmIcon={externalWallet.chainType === NetworkChainType.EVM ? walletIcon : undefined}
          menuOpen={!!walletMenuOpen}
          cardanoAddress={
            externalWallet.chainType === NetworkChainType.CARDANO ? (externalWallet.address as string) : cardanoAddress
          }
          evmAddress={
            externalWallet.chainType === NetworkChainType.EVM ? (externalWallet.address as string) : evmAddress
          }
          onClickConnect={(e: React.MouseEvent<HTMLElement>) => {
            // on mobile just open wallet connect, cardano does not work on mobile
            if (isMobile) {
              evmOpen();
              return;
            }
            if (cardanoAddress || !IS_CARDANO_ENABLED) evmOpen();
            else if (evmAddress) cardanoSetOpen(true);
            else onTriggerMenu(e);
          }}
          onClickCardanoAddress={(e) => {
            setChain('cardano');
            onTriggerMenu(e);
          }}
          onClickEvmAddress={(e) => {
            setChain('evm');
            onTriggerMenu(e);
          }}
          connectingCardano={cardanoIsConnecting}
          connectingEvm={evmIsConnecting}
        />
        <div className="absolute">
          {IS_CARDANO_ENABLED && (
            <>
              <CardanoPopup />
              <PickChainPopup
                handleClose={onWalletMenuClosed}
                open={chain === null ? walletMenuOpen : null}
                onWallectConnectClicked={() => {
                  evmOpen();
                  onWalletMenuClosed();
                }}
                onCardanoClicked={() => {
                  cardanoSetOpen(true);
                  onWalletMenuClosed();
                }}
              />
            </>
          )}
          <WalletMenu
            key={chain?.toString() ?? 'wallet-menu'}
            paperAddressConnected={!!externalWallet.address}
            isPaperAddress={
              (chain === NetworkChainType.EVM && externalWallet.chainType === NetworkChainType.EVM) ||
              (chain === NetworkChainType.CARDANO && externalWallet.chainType === NetworkChainType.CARDANO)
            }
            ctaButtonText={'Some CTA'}
            onCtaClicked={onCtaClicked}
            walletExplorerUrl={
              chain === 'evm'
                ? `${networks[network]?.blockExplorers.default.url}/address/${externalWallet.chainType === NetworkChainType.EVM ? externalWallet.address : evmAddress}`
                : `${networks[CARDANO_NETWORK]?.blockExplorers.default.url}/address/${externalWallet.chainType === NetworkChainType.CARDANO ? externalWallet.address : cardanoAddress}`
            }
            data={chain === 'evm' ? (dataEvm ?? []) : (dataCardano ?? [])}
            open={chain !== null ? walletMenuOpen : null}
            handleClose={onWalletMenuClosed}
            address={
              chain === 'cardano'
                ? ((cardanoAddress as string) ?? externalWallet.address)
                : (evmAddress ?? externalWallet.address)
            }
            isConnected={(chain === 'cardano' && cardanoIsConnected) || chain === 'evm' || evmIsConnected}
            onDisconnect={() => {
              chain === 'cardano' ? cardanoDisconnect?.() : evmDisconnect();
              setChain(null);
            }}
            onConnectCardano={
              !cardanoAddress
                ? () => {
                    cardanoSetOpen(true);
                    onWalletMenuClosed();
                  }
                : undefined
            }
            onConnectEvm={
              !evmAddress
                ? () => {
                    evmOpen();
                    onWalletMenuClosed();
                  }
                : undefined
            }
          />
        </div>
      </motion.div>
    </div>
  );
};
