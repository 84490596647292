export type PostWalletRegistrationQueryType = {
  status: string;
  error?: string;
};

export type GetWalletRegistrationQueryType = Array<{
  address: string;
  createdAt: string;
  origin: string;
}>;

export enum OriginTypes {
  // TODO - update
  THIS_APP = 'this-app',
}
