import { Network, NetworksResponse } from '../../../api/meld-app/networks/networks.types';

export const parseNetworks = (networks: NetworksResponse) => {
  return networks.reduce<Record<string, Network>>((prev, curr) => {
    prev[curr.name] = {
      id: curr.chainId,
      name: curr.name,
      nativeCurrency: { name: curr.defaultTokenSymbol, symbol: curr.defaultTokenSymbol, decimals: curr.decimals },
      rpcUrls: { default: { http: curr.publicRpcUrls } },
      blockExplorers: { default: { name: '', url: curr.explorerUrl } },
      contracts: {},
      lightIcon: curr.lightIcon,
      darkIcon: curr.darkIcon,
      chainType: curr.chainType,
    };

    return prev;
  }, {});
};
