import { useAppKit, useAppKitState } from '@reown/appkit/react';
import { useAccount, useDisconnect } from 'wagmi';
import { shallow } from 'zustand/shallow';

import { useStore } from '@store/store';

import { NetworkChainType } from '@api/meld-app/networks/networks.types';

import { useEvmNetwork } from '../../hooks/use-evm-network';

export const useEvmConnection = () => {
  const { open } = useAppKit();
  const { address, isConnecting, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const network = useEvmNetwork();
  const externalWallet = useStore((state) => state.externalWalletData, shallow);
  const disconnectExternalWallet = useStore((state) => state.disconnectExternalWallet);

  /**
   * we rely on this flag to determine whether its actually connecting...
   * otherwise as soon as you connect via WC it switches to "connecting" and if you close the popup
   * it gets stuck with connecting state set to true...
   */
  const { open: isOpen } = useAppKitState();

  return {
    open,
    address,
    isConnected,
    isConnecting: isConnecting && isOpen,
    disconnect: externalWallet.chainType === NetworkChainType.EVM ? disconnectExternalWallet : disconnect,
    network,
  };
};
