import { useQuery } from '@tanstack/react-query';

import { useStore } from '@store/store';

/**
 * If you need to show histoy data you can fetch here
 */
export const useUserPastHistory = () => {
  const cardanoAddress = useStore((state) => state.cardanoData.cardanoAddress);
  const evmAddress = useStore((state) => state.evmData.evmAddress);

  const {
    isLoading: isLoadingEvm,
    fetchStatus: fetchStatusEvm,
    data: dataEvm,
    isRefetching: isRefetchingEvm,
  } = useQuery(['GET_USER_PAST_HISTORY_EVM', evmAddress], () => [], {
    refetchOnWindowFocus: false,
    enabled: !!evmAddress,
    refetchOnMount: false,
  });

  const {
    isLoading: isLoadingCardano,
    fetchStatus: fetchStatusCardano,
    data: dataCardano,
    isRefetching: isRefetchingCardano,
  } = useQuery(['GET_USER_PAST_HISTORY_CARDANO', cardanoAddress], () => [], {
    refetchOnWindowFocus: false,
    enabled: !!cardanoAddress,
    refetchOnMount: false,
  });

  return {
    isLoadingEvm,
    fetchStatusEvm,
    dataEvm,
    isRefetchingEvm,
    isLoadingCardano,
    fetchStatusCardano,
    dataCardano,
    isRefetchingCardano,
  };
};
