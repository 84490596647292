export const isDevEnv = import.meta.env.VITE_APP_ENV === 'development';
export const isStagingEnv = import.meta.env.VITE_APP_ENV === 'staging';
export const isProdEnv = import.meta.env.VITE_APP_ENV === 'production';
export const isNotProdEnv = import.meta.env.VITE_APP_ENV !== 'production';

export const checkEnvVars = () => {
  const vitalEnvVars: Array<string> = [
    'VITE_APP_WALLET_CONNECT_PROJECT_ID',
    'VITE_APP_API_BASE_URL',
    'VITE_APP_ENV',
    'VITE_APP_CARDANO_SUPPORTED',
    'VITE_APP_PAPER_WALLET_SUPPORTED',
    'VITE_APP_WALLET_MENU_CTA',
  ];

  vitalEnvVars.map((env) => {
    if (!import.meta.env[env]) {
      if (isProdEnv) {
        throw Error(`Error: env var ${env} is NOT set.`);
      }
      console.error(`Error: env var ${env} is NOT set.`);
    }
  });

  if (!isProdEnv && location.search.includes('debug')) {
    console.debug(import.meta.env);
  }
};
