import { ValueInputError } from '@typings/ValueInputError';
import { StateSlice } from '@typings/state-creator.types';
import { WalletToken } from '@typings/wallet-asset.types';
import { Lucid } from 'lucid-cardano';

import { LiquidationsType } from '@api/liquidations/types';
import { AvailableToken } from '@api/meld-app/available-tokens/available-tokens.types';
import { NetworkChainType } from '@api/meld-app/networks/networks.types';
import { CardanoNft, EvmNft } from '@api/meld-app/nfts/nfts-query.types';

import { WalletMenuProps } from '@components/user-menu/wallet-menu';
import {
  ExtCardanoWallet,
  SupportedCardanoWallet,
} from '@components/with-cardano-external-wallets/with-cardano-external-wallet.types';

import { setSbAssets, setSbAssetsByContract, setSbAssetsByTokenId, setSbAssetsObj } from '@utils/assets-helper';
import { INetworkNames, Networks, setEvmNetworks, setNetworkNames, setNetworks } from '@utils/networks/networks';

type EVMData = {
  evmConnectedChainId?: number | undefined;
  evmRequiresApproval?: boolean;
  evmWalletName?: string;
  evmTxFeeData?: { maxFeePerGas: bigint; maxPriorityFeePerGas: bigint };
  evmTransactionData?: WalletMenuProps['data'] | null;
  notBroadcastedExternalEVMWalletAddress: string | null;
  evmAddress: string | null;
  evmAddressRegistered: boolean;
  evmNfts: Array<EvmNft>;
};

type CardanoData = {
  cardanoNfts: Array<CardanoNft>;
  cardanoWrongNetwork?: boolean;
  cardanoTransactionData: WalletMenuProps['data'] | null;
  cardanoAddress: string | null;
  cardanoDisconnectWallet: (() => void) | null;
  cardanoConnectWallet: ((walletName: SupportedCardanoWallet) => Promise<void>) | null;
  cardanoWalletRegistered: boolean;
  cardanoWalletName: SupportedCardanoWallet | null;
  cardanoWallet: Lucid | null;
  cardanoConnected: boolean;
  cardanoLoaded: boolean;
  cardanoConnecting: boolean;
  cardanoNotBroadcastedAddress: string | null;
  cardanoWallets: ExtCardanoWallet[];
};

export type AppData = {
  transactionCost: string;
  notEnoughToken: boolean;
  liquidations: LiquidationsType;
  selectedUser: string | null;
  selectedFromPointX: number | null;
  selectedFromPointY: number | null;
};

type InputData = {
  isTransferingFullNativeAmount: boolean;
  amount: string;
  /**
   * this is used when bridging total cardano balance where we deduct 2 ADA
   * also used when bridging total native token balance on EVM
   */
  realAmount: string;
  inputError: ValueInputError | null;
  key: number;
};

// Paper wallet
type ExternalWalletData = {
  chainType?: NetworkChainType;
  address?: string;
};

export type appSliceType = {
  userTokens: WalletToken[];
  setUserTokens: (userTokens: WalletToken[]) => void;

  externalWalletData: ExternalWalletData;
  setExternalWalletData: (data: Partial<ExternalWalletData>) => void;

  disconnectExternalWallet: () => void;

  evmData: EVMData;
  setEvmData: (newEvmData: Partial<EVMData>) => void;

  cardanoData: CardanoData;
  setCardanoData: (newCardanoData: Partial<CardanoData>) => void;

  appData: AppData;
  setAppData: (newAppData: Partial<AppData>) => void;

  inputData: InputData;
  setInputData: (newInputData: Partial<InputData>, resetAmount?: boolean) => void;

  numberFormatting: { decimalSeparator: string; thousandsSeparator: string };
  setNumberFormatting: (data: { decimalSeparator: string; thousandsSeparator: string }) => void;

  // this is a hack to make the input reset
  detailsKey: number;
  setDetailsKey: (detailsKey: number) => void;

  selectedWalletToken: undefined | WalletToken;
  setSelectedWalletToken: (selectedWalletToken: undefined | WalletToken) => void;

  updateSelectedWalletToken: (selectedWalletToken: undefined | WalletToken) => void;

  cardanoMenuIsOpen: boolean;
  setIsCardanoMenuOpen: (cardanoMenuIsOpen: boolean) => void;

  setAvailableTokens: (availableTokens: AvailableToken[]) => void;
  availableTokens: AvailableToken[] | null;

  setLendingPoolContractAddress: (lendingPoolContractAddress: string) => void;
  lendingPoolContractAddress: string | null;

  setNetworks: (networks: Networks) => void;
  networks: Networks | null;
};

export const createAppSlice: StateSlice<appSliceType> = (set) => ({
  userTokens: [],
  setUserTokens: (userTokens: WalletToken[]) => set({ userTokens }, false, 'setUserTokens'),

  lendingPoolContractAddress: null,
  setLendingPoolContractAddress: (lendingPoolContractAddress: string) =>
    set({ lendingPoolContractAddress }, false, 'setLendingPoolContractAddress'),

  externalWalletData: {},
  setExternalWalletData: (externalWalletData: Partial<ExternalWalletData>) =>
    set(
      (oldState) => ({ ...oldState, externalWalletData: { ...oldState.externalWalletData, ...externalWalletData } }),
      false,
      'setExternalWalletData',
    ),

  disconnectExternalWallet: () =>
    set((oldState) => ({ ...oldState, externalWalletData: {} }), false, 'disconnectExternalWallet'),

  evmData: {
    notBroadcastedExternalEVMWalletAddress: null,
    evmAddress: null,
    evmAddressRegistered: false,
    evmNfts: [],
  },
  setEvmData: (newEvmData: Partial<EVMData>) =>
    set((oldState) => ({ ...oldState, evmData: { ...oldState.evmData, ...newEvmData } }), false, 'setEvmData'),

  cardanoData: {
    cardanoWrongNetwork: false,
    cardanoAddress: null,
    cardanoTransactionData: [],
    cardanoNotBroadcastedAddress: null,
    cardanoDisconnectWallet: null,
    cardanoConnected: false,
    cardanoLoaded: false,
    cardanoConnecting: false,
    cardanoWallets: [],
    cardanoWalletRegistered: false,
    cardanoWalletName: null,
    cardanoWallet: null,
    cardanoNfts: [],
    cardanoConnectWallet: null,
  },
  setCardanoData: (newCardanoData: Partial<CardanoData>) =>
    set(
      (oldState) => ({ ...oldState, cardanoData: { ...oldState.cardanoData, ...newCardanoData } }),
      false,
      'setEvmData',
    ),

  numberFormatting: { decimalSeparator: '.', thousandsSeparator: ',' },
  setNumberFormatting: (numberFormatting: { decimalSeparator: string; thousandsSeparator: string }) =>
    set({ numberFormatting }, false, 'setNumberFormatting'),

  appData: {
    transactionCost: '',
    notEnoughToken: false,
    liquidations: [],
    selectedUser: null,
    selectedFromPointX: null,
    selectedFromPointY: null,
  },
  setAppData: (newAppData: Partial<AppData>) =>
    set((oldState) => ({ ...oldState, appData: { ...oldState.appData, ...newAppData } }), false, 'setAppData'),

  inputData: { isTransferingFullNativeAmount: false, amount: '', realAmount: '', inputError: null, key: 0 },
  setInputData: (newInputData: Partial<InputData>, resetAmount = false) =>
    set(
      (oldState) => ({
        ...oldState,
        inputData: {
          ...oldState.inputData,
          ...newInputData,
          key: oldState.inputData.key + (resetAmount ? 1 : 0),
        },
      }),
      false,
      'setInputData',
    ),

  detailsKey: 0,
  setDetailsKey: (detailsKey: number) => set({ detailsKey }, false, 'setDetailsKey'),

  selectedWalletToken: undefined,
  setSelectedWalletToken: (selectedWalletToken: undefined | WalletToken) => {
    set(
      (oldState) => ({
        ...oldState,
        selectedWalletToken,
        transactionCost: '',
        evmData: { ...oldState.evmData, evmRequiresApproval: false },
        appData: {
          ...oldState.appData,
          notEnoughToken: false,
          transactionCost: '',
        },
        inputData: {
          ...oldState.inputData,
          isTransferingFullNativeAmount: false,
          amount: '',
          realAmount: '',
          key: oldState.inputData.key + 1,
        },
      }),
      false,
      'setSelectedWalletToken',
    );
  },

  updateSelectedWalletToken: (selectedWalletToken: undefined | WalletToken) => {
    set(
      (oldState) => ({
        ...oldState,
        selectedWalletToken,
      }),
      false,
      'updateSelectedWalletToken',
    );
  },

  cardanoMenuIsOpen: false,
  setIsCardanoMenuOpen: (cardanoMenuIsOpen: boolean) => set({ cardanoMenuIsOpen }, false, 'setIsCardanoMenuOpen'),

  availableTokens: null,
  setAvailableTokens: (newAvailableTokens: AvailableToken[]) => {
    setSbAssets(newAvailableTokens);
    setSbAssetsObj(
      newAvailableTokens.reduce<Record<string, AvailableToken>>((prev, curr) => {
        prev[curr.name] = curr;
        return prev;
      }, {}),
    );
    setSbAssetsByTokenId(
      newAvailableTokens.reduce<Record<string, AvailableToken>>((prev, curr) => {
        prev[curr.tokenId] = curr;
        return prev;
      }, {}),
    );
    setSbAssetsByContract(
      newAvailableTokens.reduce<Record<string, AvailableToken>>((prev, curr) => {
        prev[curr.contract] = curr;
        return prev;
      }, {}),
    );
    set({ availableTokens: newAvailableTokens }, false, 'setAvailableTokens');
  },

  networks: null,

  setNetworks: (newNetworks: Networks) => {
    setNetworks(newNetworks);

    // we do this to be able to access correct network manually
    const updatedNetworkNames: INetworkNames = Object.keys(newNetworks).reduce((prev, curr) => {
      let key: keyof INetworkNames;
      switch (curr) {
        case 'preprod':
          key = 'cardano';
          break;
        case 'mumbai':
          key = 'ethereum';
          break;
        case 'fuji':
          key = 'avalanche';
          break;
        case 'kanazawa':
          key = 'meld';
          break;
        default:
          key = curr as keyof INetworkNames;
          break;
      }
      prev[key] = curr;
      return prev;
    }, {} as INetworkNames);

    setNetworkNames(updatedNetworkNames);
    setEvmNetworks(Object.values(newNetworks).filter((network) => network.chainType === 'evm'));
    set({ networks: newNetworks }, false, 'setNetworks');
  },
});
