import { useAppKitState } from '@reown/appkit/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { IS_PAPER_WALLET_ENABLED } from 'src/contants/app';

import { useCardanoConnection } from '@components/connect-button/use-cardano-connection';

import { cn } from '@utils/cn';

import { ConnectPaperWallet } from './connect-paper-wallet';

/**
 * @remarks isOpen from useWeb3ModalState() triggers before any other method,
 * it is the quickest way to find where the popup is on screen.
 *
 * we've tried using a resize observer to monitor for changes within the popup,
 * to determine whether to show our hide our UI but that proved too slow vs the
 * current solution.
 */
export const WCPopupAddon = () => {
  const { open: isOpen } = useAppKitState();
  const [offsetLeft, setOffsetLeft] = useState(-1);
  const [offsetTop, setOffsetTop] = useState(-1);
  const [ourPopupVisible, setOurPopupVisible] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const { address: cardanoAddress } = useCardanoConnection();
  const animatingBackRef = useRef(false);
  const [key, setKey] = useState(0);

  // if you close and open the modal fast it stays with open = true but no UI...
  const [isWeb3ModalOpen, setIsWeb3ModalOpen] = useState(false);

  const internalIsOpenRef = useRef(false);

  const setWalletInstalledWhite = useRef(false);
  const setWhatIsAWalletButtonTransparent = useRef(false);

  /**
   * This checks if a given view of the web3modal is open and if it is it
   * updates the state so we can mount our UI.
   */
  const checkIsOpen = useCallback(() => {
    const wcModal = document.querySelector('w3m-modal');
    if (wcModal) {
      const anyElementsWithinModal = wcModal.shadowRoot?.querySelectorAll('*').length;
      if (!anyElementsWithinModal) {
        setIsWeb3ModalOpen(false);
      }

      const wuiCard = wcModal.shadowRoot?.querySelector('wui-card');

      // target view (means our UI should be displaying)
      const w3mConnectView = wuiCard?.querySelector('w3m-router')?.shadowRoot?.querySelector('w3m-connect-view');

      // go to question mark on top left, button at the button is fully white so we update it here
      const whatIsAWalletViewButton = wuiCard
        ?.querySelector('w3m-router')
        ?.shadowRoot?.querySelector('w3m-what-is-a-wallet-view')
        ?.shadowRoot?.querySelector('wui-flex')
        ?.querySelector('wui-button')
        ?.shadowRoot?.querySelector('button');

      if (whatIsAWalletViewButton) {
        whatIsAWalletViewButton.style.background = 'transparent';
        setWhatIsAWalletButtonTransparent.current = true;
      }

      // installed wallets show up with an "installed" label, this fixes the color of the label text
      const wallets = !setWalletInstalledWhite.current
        ? w3mConnectView?.shadowRoot?.querySelector('wui-flex')?.querySelectorAll('wui-list-wallet')
        : undefined;

      // loop through each wallet to fix all cases
      if (wallets) {
        wallets.forEach((a) => {
          const tagElement = a?.shadowRoot
            ?.querySelector('wui-tag')
            ?.shadowRoot?.querySelector('wui-text')
            ?.shadowRoot?.querySelector('slot');

          if (tagElement) tagElement.style.color = 'white';
        });
        setWalletInstalledWhite.current = true;
      }

      // target view is on screen update what we need if haven't already marked our UI as visible
      if (w3mConnectView && !internalIsOpenRef.current) {
        internalIsOpenRef.current = true;

        if (cardanoAddress && wuiCard && IS_PAPER_WALLET_ENABLED) {
          inputRef?.current?.focus();
          // hack to regain focus in some cases
          setTimeout(() => inputRef?.current?.focus(), 200);
          setOurPopupVisible(1);
          wuiCard.style.borderBottomRightRadius = '0px';
          wuiCard.style.borderBottomLeftRadius = '0px';
          animatingBackRef.current = true;
        }
      }

      // target view is off screen update what we need if haven't already marked our UI as invisible
      if (!w3mConnectView && internalIsOpenRef.current && wuiCard) {
        internalIsOpenRef.current = false;
        setWalletInstalledWhite.current = false;
        setWhatIsAWalletButtonTransparent.current = false;

        if (cardanoAddress && IS_PAPER_WALLET_ENABLED) {
          setKey((key) => key + 1);
          setOurPopupVisible(0);
          wuiCard.style.borderBottomRightRadius = '27px';
          wuiCard.style.borderBottomLeftRadius = '27px';
        }
        // reset our form UI when user goes back and forth inside web3modal's popup
      }

      // if the web3modal is on screen keep checking for changes inside it
      if (wuiCard) setTimeout(() => checkIsOpen(), 10);
    }
  }, [cardanoAddress]);

  const checkIsOpenRef = useRef(checkIsOpen);

  useEffect(() => {
    checkIsOpenRef.current = checkIsOpen;
  }, [checkIsOpen]);

  useEffect(() => {
    if (isOpen) {
      checkIsOpenRef.current();
      setIsWeb3ModalOpen(true);
    }
  }, [isOpen, cardanoAddress]);

  useEffect(() => {
    if (isOpen && cardanoAddress) {
      const wcModal = document.querySelector('w3m-modal');
      if (wcModal) {
        const el = wcModal.shadowRoot?.querySelector('wui-card');

        if (el && IS_PAPER_WALLET_ENABLED) {
          // update some of the popup's styling so we can attach our own UI and make it look native
          el.style.position = 'relative';
          el.style.top = '-100px';
          el.style.animation = 'none';
          el.style.boxShadow = 'none';
          el.style.transition = 'border-radius 0.2s';
          el.style.borderBottomRightRadius = '0px';
          el.style.borderBottomLeftRadius = '0px';
          el.style.animationTimingFunction = 'unset';

          setTimeout(() => {
            const offsetLeft = el.offsetLeft;
            const top = el.offsetTop;
            const height = el.offsetHeight;
            setOffsetTop(top + height);
            setOffsetLeft(offsetLeft);
            setOurPopupVisible(1);
          }, 10);
        }
      }
    } else {
      animatingBackRef.current = false;
    }
  }, [isOpen, cardanoAddress]);

  // handle resize
  useEffect(() => {
    const handler = () => {
      const wcModal = document.querySelectorAll('w3m-modal');
      if (wcModal && wcModal.length) {
        const el = wcModal[0].shadowRoot?.querySelector('wui-card');
        if (el && IS_PAPER_WALLET_ENABLED) {
          const offsetLeft = el.offsetLeft;
          const top = el.offsetTop;
          const height = el.offsetHeight;
          setOffsetTop(top + height);
          setOffsetLeft(offsetLeft);
        }
      }
    };

    window.addEventListener('resize', handler);

    return () => window.removeEventListener('resize', handler);
  }, []);

  if (!IS_PAPER_WALLET_ENABLED) return null;

  return cardanoAddress
    ? createPortal(
        <>
          <div
            style={{
              left: offsetLeft - 0.5,
              top: offsetTop - 5,
            }}
            className={cn(
              'pointer-events-none fixed z-[1000] box-border w-[360px] rounded-bl-[27px] rounded-br-[27px] bg-transparent px-3 pb-5 pt-2 opacity-0 will-change-[top]',
              ourPopupVisible && 'pointer-events-auto bg-[#ec0046] opacity-100',
              (!isOpen || !isWeb3ModalOpen) && 'opacity-0',
            )}
          >
            <ConnectPaperWallet key={key.toString()} inputRef={inputRef} />
          </div>
        </>,
        document.body,
      )
    : null;
};
